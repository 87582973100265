.gallery {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid gray;
    border-radius: 5px;
}

.gallery-display-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    border-bottom: 1px solid gray;
    height: calc(100% - 120px);
    background-color: rgb(39, 39, 39);
}

.gallery-current-image {
    max-height: 100%;
    max-width: 100%;
}

.gallery-images-wrapper {
    overflow-x: scroll;
    width: 100%;
    height: 120px;
    overflow-y: hidden;
    padding: 10px 0;
}

.gallery-images {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: 100%;
    flex-wrap: nowrap;
    padding: 0 10px;
}

.create-game-checkbox-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.create-game-checkbox-row input {
    width: 16px;
    height: 16px;

}

.create-game-checkbox-row label {
    font-size: 18px;
}

.gallery-image-wrapper {
    height: 100%;
    position: relative;
    width: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.gallery-thumbnail {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    cursor: pointer;
}

.gallery-delete-image {
    position: absolute;
    text-align: right;
    top:0;
    right: 0;
    background-color: #FFFFFF84;
    cursor: pointer;
    padding: 3px;
}

.gallery-delete-image:hover {
    font-weight: bold;
    text-decoration: underline;
}