html {
    overflow-y: hidden;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #A4A6BB;
}

main {
    width: 1024px;
    height: calc(100% - 50px);
    background-color: white;
    overflow-y: auto;
}