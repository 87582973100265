.my-game {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 15px;
    padding: 10px;
    gap: 20px;
}

.my-game-row {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.my-game-image {
    max-height: 100%;
}

.my-game-upload-details {
    max-height: 100%;
    word-wrap: break-word;
    max-lines: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.my-game-title {
    font-size: 20px;
    font-weight: bold;
    max-lines: 1;
    min-width: 150px;
}

.my-game-interactions {
    flex:1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 5px;
}

.my-game-button {
    background-color: #004b66;
    color: white;
    border-radius: 10px;
    padding: 5px 10px;
    border-width: 1px;
    border-color: #004b66;
    cursor: pointer;
}

.my-game-button:hover {
    box-shadow: 0 0 0 1px #004b66;
}

.my-game-button.red {
    background-color: white;
    border-color: red;
    color: red;
}

.my-game-button.red:hover {
    box-shadow: 0 0 0 1px red;
}

.my-game-details {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.my-game-details-section {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 16px;
}

.my-game-details-section .published {
    font-weight: bold;
    color: green;
}

.my-game-details-section .unpublished {
    font-weight: bold;
    
}