.header {
    height: 50px;
    background-color: white;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid lightgray;
    align-items: center;
    padding: 0 20px;
    gap: 10px
}

.logo-wrapper {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.logo-wrapper img {
    width: 100%;
    height: 100%;
}

.header h1 {
    margin: 0;
}

.header-username {
    font-size: 18px;
    transition: .4s;
    cursor: pointer;
    color: #004b66;
}

.header-username:hover {
    text-decoration: underline;
}

.profile-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
    gap: 10px;
    align-items: center;
}

.profile-container button {
    height: 35px;
    background-color: white;
    border: 1px solid gray;
    color: black;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 18px;
    cursor: pointer;
    transition: .4s;
}