.create-game-wrapper {
    padding: 20px;
}

.create-game-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.create-game-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding-bottom: 20px;
}

.create-game-input {
    font-size: 18px;
    padding: 3px 10px;
    width: calc(75% - 20px);
}

.create-game-picker {
    font-size: 18px;
    padding: 3px 10px;
}

.create-game-gallery {
    width: 75%;
    height: 500px;
}

.create-game-file-picker {
    display: none;
}

.create-game-image-picker-button {
    background-color: white;
    height: 30px;
    border: 1px solid gray;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
}

.create-game-description-wrapper {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 5px;
}

.create-game-description-wrapper label {
    font-size: 18px;
}

.create-game-description {
    resize: none;
    height: 150px;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 5px;
}

.create-game-header-button {
    height: 35px;
    background-color: white;
    border: 1px solid gray;
    color: black;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 18px;
    cursor: pointer;
    transition: .4s;
}

.create-game-header-button:hover {
    color: #004b66;
}

.create-game-header-button.blue {
    background-color: #007199;
    color: white;
}

.create-game-header-button.blue:hover {
    background-color: #004b66;
}

.header-buttons-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
}