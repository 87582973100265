#user-profile {
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: calc(100% - 20px);
    overflow-y: auto;
}

.profile-details-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.profile-picture {
    height: 40px;
    width: 40px;
    border-radius: 20px;
}

.profile-user-name {
    font-size: 18px;
    font-weight: bold;
}

.profile-my-games-wrapper {
    padding: 20px;
}

.profile-my-games-list {
    display: flex;
    flex-direction: column;
    min-height: 40px;
    gap:20px;
    padding: 10px
}

.profile-logout {
    color: #004b66;
    text-decoration: underline;
    cursor: pointer;
}

.profile-user-name {
    display: flex;
    flex-direction: column;
}